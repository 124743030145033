import type { MetaArgs } from '@remix-run/node';
import { createMetaDescription } from '@modules/seo';

export const getProfessionalPageMetaDescription = (
    props: MetaArgs,
    descriptions: Array<string | null | undefined>,
    professionalName: string
) => {
    const urlSegment = props.params.urlSegment;
    const pathname = props.location.pathname;

    const key = pathname.replace(`/professional/${urlSegment}/`, '');

    const shortDescription = createMetaDescription(descriptions);

    const mapPage: { [key: string]: string } = {
        info: shortDescription,
        'our-products': `Shop our unique and curated selection of products from ${professionalName}`,
        'case-studies': `Explore ${professionalName} projects. See how you can add products into your home to create a unique place`,
        journal: `Read ${professionalName} articles to help you create a unique place.`,
        videos: `Watch ${professionalName} videos to see the latest news, products.`,
        partners: `Check out ${professionalName} partners and network to complete your building project.`,
        contact: `Contact ${professionalName} for any comments, help or for questions about products.`,
    };

    return mapPage.hasOwnProperty(key) ? `${mapPage[key]}` : shortDescription;
};
