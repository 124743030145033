import type { MetaArgs } from '@remix-run/node';

export const getProfessionalPageTitle = (props: MetaArgs, title: string) => {
    const urlSegment = props.params.urlSegment;
    const pathname = props.location.pathname;

    const key = pathname.replace(`/professional/${urlSegment}/`, '');

    const mapTitle: { [key: string]: string } = {
        info: `About ${title}`,
        'our-products': `Shop Our Products | ${title}`,
        'case-studies': `Our Projects | ${title}`,
        journal: `Articles | ${title}`,
        videos: `Videos | ${title}`,
        partners: `Network | ${title}`,
        contact: `Contact Us | ${title}`,
    };

    return mapTitle.hasOwnProperty(key) ? `${mapTitle[key]}` : title;
};
